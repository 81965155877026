/** @jsx jsx */
import { jsx, Box, Grid, Themed, Label, Input, Radio, Button, Spinner, Card } from "theme-ui";
import React from "react";

import Resultado from "./Resultado";

export default function Results({ params }) {
  const token = new URLSearchParams(params).get("token");
  return token ? <Resultado token={token} /> : <Inscripcion />;
}

function Inscripcion() {
  return (
    <React.Fragment>
      <Themed.h1>Agenda tus clases 📚</Themed.h1>
      <Themed.p>
        <strong>Très bien !</strong> Nos alegra que te hayas decidido a aprender francés.
      </Themed.p>
      <Box
        as="form"
        action={"/api/inscribete"}
        method="POST"
        sx={{ ":invalid > button": { pointerEvents: "none", variant: "buttons.disabled" } }}
      >
        <ContactInfo />
        <ProductSelector />
        <PayButton />
        <input name="isFirst" type="hidden" value={true} />
      </Box>
      <Themed.h3> Consideraciones</Themed.h3>
      <ul>
        <li> El valor incluye material digital de estudio personal y de trabajo para las sesiones.</li>
        <li>
          Una vez confirmado el pago, se enviará un correo electrónico con todo el detalle para iniciar las clases.
        </li>
        <li>
          Para cancelar una sesión se requiere un aviso de 24 horas de antelación, de lo contrario se considera
          realizada; exceptuando situaciones imprevistas o emergencias.
        </li>
      </ul>
    </React.Fragment>
  );
}

function ContactInfo() {
  return (
    <React.Fragment>
      <Label htmlFor="name">Nombre</Label>
      <Input type="text" name="name" required />
      <Label htmlFor="email">Correo electrónico</Label>
      <Input type="email" name="email" required />
    </React.Fragment>
  );
}

function ProductSelector() {
  return (
    <React.Fragment>
      <Themed.p>Escoge cuántas clases quieres</Themed.p>
      <Grid columns={[1, 2]} gap={3}>
        <Card>
          <Label variant="radio" sx={{ alignItems: "center" }}>
            <Radio required name="product" value="1 clase" />
            <div>
              <Themed.h3 sx={{ my: 0 }}>1 clase</Themed.h3>
              <Themed.p sx={{ my: 0 }}>
                $20.000
                <br />
                <small>(valor online)</small>
              </Themed.p>
            </div>
          </Label>
        </Card>
        <Card>
          <Label variant="radio" sx={{ alignItems: "center" }}>
            <Radio required name="product" value={"8 clases"} />
            <div>
              <Themed.h3 sx={{ my: 0 }}>8 clases</Themed.h3>
              <Themed.p sx={{ my: 0 }}>
                $160.000
                <br />
                <small>(valor online)</small>
              </Themed.p>
            </div>
          </Label>
        </Card>
      </Grid>
    </React.Fragment>
  );
}

function PayButton() {
  const [redirecting, set] = React.useState(false);
  return (
    <Button
      variant={redirecting ? "disabled" : "primary"}
      sx={{ my: 3, height: 42, width: "100%" }}
      onClick={() => set(true)}
      type="submit"
    >
      {redirecting ? <Spinner color="muted" size={22} /> : "Inscríbete"}
    </Button>
  );
}
