exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-cours-js": () => import("./../../../src/pages/cours.js" /* webpackChunkName: "component---src-pages-cours-js" */),
  "component---src-pages-films-mdx": () => import("./../../../src/pages/films.mdx" /* webpackChunkName: "component---src-pages-films-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-inscribete-mdx": () => import("./../../../src/pages/inscribete.mdx" /* webpackChunkName: "component---src-pages-inscribete-mdx" */),
  "component---src-pages-link-[id]-mdx": () => import("./../../../src/pages/link/[id].mdx" /* webpackChunkName: "component---src-pages-link-[id]-mdx" */),
  "component---src-pages-link-index-mdx": () => import("./../../../src/pages/link/index.mdx" /* webpackChunkName: "component---src-pages-link-index-mdx" */),
  "component---src-pages-link-nuevo-mdx": () => import("./../../../src/pages/link/nuevo.mdx" /* webpackChunkName: "component---src-pages-link-nuevo-mdx" */),
  "component---src-pages-message-js": () => import("./../../../src/pages/message.js" /* webpackChunkName: "component---src-pages-message-js" */),
  "component---src-pages-series-mdx": () => import("./../../../src/pages/series.mdx" /* webpackChunkName: "component---src-pages-series-mdx" */)
}

