/** @jsx jsx */
import { jsx, Themed, Box, Button, Card, Spinner } from "theme-ui";
import React from "react";

export default function LinkRead({ id }) {
  const [data, setData] = React.useState(false);
  React.useEffect(() => {
    async function fetchData() {
      const response = await fetch("/api/link-read", { method: "POST", body: id });
      const json = await response.json();
      setData(json);
    }
    fetchData();
  }, [id]);

  // const fetchData = async () => {
  //   const response = await fetch("/api/link-pay", { method: "POST", body: {id} });
  //   const json = await response.json();
  //   console.log(json);
  // };

  if (data) {
    return (
      <Card sx={{ textAlign: "center", m: "auto" }}>
        <Themed.h2>👋 Salut, {data.name} !</Themed.h2>
        <Themed.p>
          Aquí puedes pagar tu
          <br /> <strong sx={{ color: "primary", fontSize: 3 }}>{data.product}</strong>.
        </Themed.p>
        <Themed.p>
          Valor: <strong sx={{ color: "primary", fontSize: 3 }}>${data.amount}</strong>
        </Themed.p>
        <Box
          as="form"
          action={"/api/link-pay"}
          method="POST"
          sx={{ ":invalid > button": { pointerEvents: "none", variant: "buttons.disabled" } }}
        >
          <input type="hidden" name="id" value={id} />
          <Button sx={{ width: "100%" }} type="submit">
            Continuar con WebPay Plus
          </Button>
        </Box>
      </Card>
    );
  }
  return <Spinner sx={{ m: "auto" }} />;
}
