/** @jsx jsx */
import { jsx, Image, Themed, Card } from "theme-ui";
import { useStaticQuery, graphql } from "gatsby";
import { useSpringCarousel } from "react-spring-carousel";

export default function Opiniones() {
  const data = useStaticQuery(graphql`
    query Opiniones {
      allOpinion {
        nodes {
          name
          image
          text
          score
        }
      }
    }
  `);
  const { nodes } = data.allOpinion;

  return (
    <div sx={{ overflowX: "hidden" }}>
      <Carousel items={nodes} />
    </div>
  );
}

function Carousel({ items }) {
  const { carouselFragment, slideToNextItem } = useSpringCarousel({
    withLoop: true,
    items: items.map((opinion, idx) => ({
      id: "id-" + idx,
      renderItem: (
        <Card sx={{ mx: 2, my: 2, width: "100%" }}>
          <div sx={{ display: "flex", alignItems: "center", my: 2 }}>
            {opinion.image ? (
              <Image
                src={opinion.image}
                sx={{
                  width: "48px",
                  height: "48px",
                  borderRadius: "99999px",
                }}
                variant="avatar"
              />
            ) : (
              <div
                sx={{
                  width: 48,
                  height: 48,
                  borderRadius: 99999,
                  bg: "text",
                  display: "flex",
                }}
              >
                <Themed.h4 sx={{ color: "background", m: "auto" }}>{opinion.name.charAt(0).toUpperCase()}</Themed.h4>
              </div>
            )}
            <div sx={{ ml: 2 }}>
              <Themed.h4 sx={{ my: 0 }}>{opinion.name}</Themed.h4>
              <Themed.p sx={{ my: 0 }}>{[...Array(opinion.score).keys()].map(() => "⭐").join(" ")}</Themed.p>
            </div>
          </div>
          <Themed.p
            sx={{
              mt: 0,
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              lineClamp: 4,
              WebkitLineClamp: 4,
              WebkitBoxOrient: "vertical",
            }}
          >
            {opinion.text.replace("...leer más", "")}
          </Themed.p>
        </Card>
      ),
    })),
  });
  setInterval(slideToNextItem, 7 * 1000);
  return carouselFragment;
}
