import { Grid, Button, Flex } from "theme-ui";
import { Link as GatsbyLink } from "gatsby";
import Opiniones from "../../../../src/components/Opiniones";
import * as React from 'react';
export default {
  Grid,
  Button,
  Flex,
  GatsbyLink,
  Opiniones,
  React
};