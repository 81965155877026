/** @jsx jsx */
import { jsx, Themed, Spinner, Flex, Card } from "theme-ui";
import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import Confetti from "react-confetti";

export default function Status({ token }) {
  const [data, setData] = useState(false);

  useEffect(() => {
    const getStatus = async () => {
      const response = await fetch(`/api/estado?token=${token}`);
      const data = await response.json();
      setData(data);
    };
    getStatus();
  }, [token]);

  const { statusResponse, error } = data;
  if (statusResponse) {
    const authorized = statusResponse.response_code === 0 && statusResponse.status === "AUTHORIZED";
    return authorized ? <Success data={statusResponse} /> : <Failure buyOrder={statusResponse.buy_order} />;
  }

  if (error) {
    navigate("/");
  }

  return <Spinner sx={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }} />;
}

function Success({ data }) {
  const { width, height } = useWindowSize();
  return (
    <React.Fragment>
      <Confetti width={width} height={height} />
      <Themed.h1 sx={{ textAlign: "center" }}>
        🥳
        <br />
        Magnifique !
      </Themed.h1>
      <Themed.p>
        Nos pondremos en contacto a la brevedad para coordinar los detalles de tu próxima clase. À bientôt !
      </Themed.p>
      <TransactionDetails data={data} />
    </React.Fragment>
  );
}

function Failure({ buyOrder }) {
  return (
    <React.Fragment>
      <Themed.h1>🤔 Hmmm... algo salió mal</Themed.h1>
      <Themed.p>
        La{" "}
        <strong>
          orden de compra: <span sx={{ color: "primary" }}>{buyOrder}</span>
        </strong>{" "}
        fue rechazada.
      </Themed.p>
      <Themed.p>Las posibles causas de este rechazo son:</Themed.p>
      <Themed.ul>
        <Themed.li>Error en el ingreso de los datos de su tarjeta de crédito o débito.</Themed.li>
        <Themed.li>Su tarjeta de crédito o débito no cuenta con saldo suficiente.</Themed.li>
        <Themed.li>Tarjeta aún no habilitada en el sistema financiero.</Themed.li>
      </Themed.ul>
    </React.Fragment>
  );
}

function TransactionDetails({ data }) {
  const dateString = new Date(data.transaction_date).toLocaleDateString("es", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return (
    <Card>
      <Themed.h4 sx={{ mt: 0, textAlign: "center" }}>Detalles de la transacción</Themed.h4>
      {[
        { key: "Número de orden", value: data.buy_order },
        { key: "Monto", value: "CLP$" + data.amount },
        { key: "Código de autorización", value: data.authorization_code },
        { key: "Fecha", value: dateString },
        { key: "Tipo de pago", value: payment_type_codes[data.payment_type_code] },
        // Tipo de cuota
        data.installments_number ? { key: "Cantidad de cuotas", value: data.installments_number } : null,
        data.installments_amount ? { key: "Monto de cuota", value: "CLP$" + data.installments_amount } : null,
        { key: "Tarjeta bancaria terminada en", value: "***" + data.card_detail?.card_number },
        { key: "Detalle", value: data.product },
      ]
        .filter((el) => el)
        .map(({ key, value }) => (
          <Flex key={key} sx={{ justifyContent: "space-between" }}>
            <Themed.p sx={{ fontSize: 1, my: 1 }}>{key}</Themed.p>
            <Themed.p sx={{ fontSize: 1, my: 1 }}>
              <strong>{value}</strong>
            </Themed.p>
          </Flex>
        ))}
    </Card>
  );
}

const payment_type_codes = {
  VD: "Venta Débito",
  VN: "Venta Normal",
  VC: "Venta en cuotas",
  SI: "3 cuotas sin interés",
  S2: "2 cuotas sin interés",
  NC: "N Cuotas sin interés",
  VP: "Venta Prepago",
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowSize() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
