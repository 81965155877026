/** @jsx jsx */
import React from "react";
import { jsx, Box, Label, Input, Button, Spinner, Themed } from "theme-ui";

export default function LinkCreate() {
  const [redirecting, setRedirecting] = React.useState(false);
  const [state, setState] = React.useState({});

  const handleChange = async (event) => {
    const value = event.target.value;
    setState({
      ...state,
      [event.target.name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setRedirecting(true);
    try {
      let response = await fetch("/api/link-create", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(state),
      });
      let link = await response.json();
      setState({ ...state, link });
      setRedirecting(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      as="form"
      sx={{ ":invalid > button": { pointerEvents: "none", variant: "buttons.disabled" } }}
      onSubmit={handleSubmit}
    >
      <Label htmlFor="name">Nombre</Label>
      <Input type="text" name="name" required onChange={handleChange} />
      <Label htmlFor="email">Correo electrónico</Label>
      <Input type="email" name="email" required onChange={handleChange} />
      <Label htmlFor="product">Descripción</Label>
      <Input name="product" id="product" required onChange={handleChange} />
      <Label htmlFor="amount">Monto</Label>
      <Input name="amount" id="amount" type="number" required onChange={handleChange} />
      <Themed.h3>{state.link}</Themed.h3>
      <Button variant={redirecting ? "disabled" : "primary"} sx={{ my: 3, height: 42, width: "100%" }} type="submit">
        {redirecting ? <Spinner color="muted" size={22} /> : "Crear pago"}
      </Button>
    </Box>
  );
}
